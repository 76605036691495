<script>
import appConfig from "@/app.config";
//   import { lineChartData } from "./chartData";
//   import { pieChart } from "./chartData";
import chart from "./chart.vue";

export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {

    chart,
  },
  data() {
    return {
      // lineChartData,
      // pieChart,
      allSurveysWithoutMe: [],
      memberSurveys: [],
      rank: null,
      members_count: null,
      chart_data: null,
      user: {}
    };
  },
  computed: {
    myInterviews() {
      return this.memberSurveys?.reduce((a, survey) => {
        let sum;
        sum = a + survey?.marketing_survey_processes?.length;
        return sum;
      }, 0);
    },
    othersInterviews() {
      return this.allSurveysWithoutMe?.reduce((a, survey) => {
        let sum;
        sum = a + survey?.marketing_survey_processes?.length;
        return sum;
      }, 0);
    },
  },
  methods: {
    async getSurveys() {
      console.log("step 49", { id: this.user.id });
      const res = await this.http.get(
        `marketing-survey-team-members/by-member-id/${this.user.id}`, null, null, true
      );
      this.memberSurveys = res?.data;
      console.log({ memberSurveys: this.memberSurveys });
      
      const result = await this.http.post("marketing-team-members/rank", {
        id: this.user.id,
      }, null, true);
      this.rank = result?.data;
      const members_count = await this.http.get("marketing-team-members", null, null, true);
      this.members_count = members_count?.data?.length;
      console.log({ rank: this.rank });
      const resp = await this.http.get("marketing-survey-team-members", null, null, true);
      this.allSurveysWithoutMe = resp?.data?.filter(
        (i) =>
          i?.marketing_team_member_id !=
          this.user.id
      );
      console.log({ allSurveysWithoutMe: this.allSurveysWithoutMe });
      const chart_res = await this.http.get("marketing-survey-processes/interviews-count", { user_id: this.user.id }, null, true);
      this.chart_data = chart_res?.data;
    },
  },

  async created() {
    this.user = JSON.parse(localStorage.user)
    console.log('user : ', this.user);
    await this.getSurveys();
  },
};
</script>

<template>
  <!-- interviews main info -->
  <div style="display: grid; grid-template-columns: 1fr 1fr; gap: 2rem">
    <!-- surveys count -->
    <div class="card">
      <h5 class="mb-4 text-center">
        {{ $t("marketing.team_member.surveys_count") }}
      </h5>
      <h1 style="font-weight: bolder" class="text-center">
        {{ memberSurveys?.map((i) => i?.marketing_survey?.id)?.length }}
      </h1>
    </div>
    <!-- interviewers count -->
    <div class="card">
      <h5 class="mb-4 text-center">
        {{ $t("marketing.team_member.interviewers_count") }}
      </h5>
      <h1 style="font-weight: bolder" class="text-center">
        {{
          memberSurveys?.reduce((a, survey) => {
            let sum;
            sum = a + survey?.marketing_survey_processes?.length;
            return sum;
          }, 0)
        }}
      </h1>
    </div>
    <!-- avarage rate -->
    <div class="card">
      <h5 class="mb-4 text-center">
        {{ $t("marketing.team_member.average_rate") }}
      </h5>
      <h1 style="font-weight: bolder" class="text-center">
        <span>{{
          memberSurveys?.filter((i) => i?.rate)?.length > 0
          ? memberSurveys
            ?.filter((i) => i?.rate)
            ?.reduce((a, survey) => a + survey?.rate, 0) /
          memberSurveys?.filter((i) => i?.rate)?.length
          : $t("marketing.not_rated")
        }}</span>
        <span v-if="memberSurveys?.filter((i) => i?.rate)?.length > 0" class="mx-1"
          style="color: #737373; font-size: 0.9rem; font-weight: light">/</span>
        <span v-if="memberSurveys?.filter((i) => i?.rate)?.length > 0"
          style="color: #737373; font-size: 0.9rem; font-weight: light">10</span>
      </h1>
    </div>
    <!-- rank -->
    <div class="card">
      <h5 class="mb-4 text-center">
        {{ $t("marketing.team_member.rank") }}
      </h5>
      <h1 style="font-weight: bolder" class="text-center">
        <span>{{ rank }}</span>
        <span class="mx-1" style="color: #737373; font-size: 0.9rem; font-weight: light">/</span>
        <span style="color: #737373; font-size: 0.9rem; font-weight: light"> {{
          members_count
        }}</span>
      </h1>
    </div>
  </div>
  <!-- interviews count during this week days -->
  <div class="row" style="margin-top: 2rem">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title mb-4">
            {{ $t("marketing.team_member.line_chart_title") }}
          </h4>
          <chart v-if="chart_data" :type="'line'" :categories="chart_data?.categories" :series="chart_data?.series"
            :title="'Interviews count this week'" x_title="Days" y_title="Interviews" series_title="Interviews"
            :yaxis_max="chart_data?.yaxis_max" />
        </div>
      </div>
    </div>
  </div>
  <!-- interviews percentage pie chart -->
  <div style="
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
        margin-top: 2rem;
      ">
    <div class="card">
      <h5 class="mb-4 text-center">
        {{ $t("marketing.team_member.interviews_percentage") }}
      </h5>
      <chart :type="'pie'" :categories="['My interviews', 'All interviews']" :series="[myInterviews, othersInterviews]" />
    </div>
  </div>
</template>
<style scoped>
.card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  border-radius: 0.4rem;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  padding: 2rem;
  margin-bottom: 0;
}
</style>
